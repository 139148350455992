import React, { useState, useEffect} from "react";
// import { CSSTransition } from "react-transition-group";
import "./style.css";

// import { ReactComponent as Chevron } from "../../Icons/chevron.svg";

export default function Post(props) {
  /*
    props: Title, Sub-title (optional), text, images
  */
  const [title, setTitle] = useState("Title");
  const [subtitle, setSubtitle] = useState("subtitle");
  const [body, setBody] = useState("body");


  useEffect(() => {
    if (props.content) {
      setTitle(props.content.Title);
      setSubtitle(props.content.Username);
      setBody(props.content.Content.content);
    }
  }, [props.content]);

  const handleSubmit = async (e)=>{
    e.preventDefault();
    let resource = "https://my-worker.hanrahma99.workers.dev"
    // let resource = "http://127.0.0.1:8787"
    let dict = {
        username:e.target[0].value,
        title:e.target[1].value,
        content:e.target[2].value
    }
    
    let postJson = JSON.stringify([dict])
    console.log(postJson)
    let response = await fetch(resource ,{method:"POST", body:postJson})
    if (response.status === 200){
        console.log('200')
        console.log(response)
    }
    else{
        console.log('Error:'+response.status)
    }
  }

  return (
    <div>
        <div
          className="article"
          style={{ transition: "500ms" }}
        >
            { props.form ? (
            <div className="dropdown">
                <form onSubmit={handleSubmit}>
                    <h1 className="title">
                        Username:
                    </h1>
                    <input type="text" />
                    <h1 className="title">
                        Title:
                    </h1>
                    <input type="text" />
                    <h1 className="title">
                        Content:
                    </h1>
                    <textarea name="Text1" cols="40" rows="5"></textarea>
                    <div></div>
                    <input type="submit" value="Post"/>
                </form>
            </div>

            ) : (
            
            <div className="dropdown">
                <div className="flexBox1">
                    <h1 className="title">{title}</h1>
                </div>

                <div>
                    <h4 className="subTitle">{"User: "+subtitle}</h4>
                    <p className="content">{body}</p>
                </div>
            </div>
            )}
            

            
        </div>
    </div>
  );
}