// import logo from './logo.svg';
import './App.css';
import Home from './Components/Views/Home'
function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <Home/>
        
      </header>
    </div>
  );
}

export default App;
