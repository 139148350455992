import React, { useState, useEffect } from "react";
import Post from "../../Post";


export default function Home(props) {

    const [content, setContent] = useState([]);

    useEffect(() => {
        getPosts();
      },
      []);

    async function getPosts() {
        try {
            let resource = "https://my-worker.hanrahma99.workers.dev?prefix="
            // let resource = "http://127.0.0.1:8787?prefix="

            
            let response = await fetch(resource ,{method:"GET"})
            response = await response.json()
            setContent(response)
        } catch (error) {
            console.log(error)
        }
        
    }


    return(

        <div className="viewContainer">
            <div className="Title">
                <h1>Posts</h1>   
            </div>
            <Post
                form={true}
            />
            <div className="Posts">
            {
                content.map( (post,i)=>{
                    return(
                        <Post
                            key={i}
                            content={post}
                        />
                    )

                })
            }
            </div>
            
        </div>
    )
}